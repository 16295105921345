.alert-wrapper{
  background: var(--element-background) !important;

  .alert-head{
    background: var(--ion-color-primary);
    text-align: center;
    margin-bottom: 15px;

    h2{
      color: white;
    }
  }
  .alert-message{
    text-align: center;
    color: var(--ion-text-color);
  }
  .alert-button-group{
    justify-content: center;

    button{
      background-color: var(--ion-color-primary);
      margin: 0 5px;
      border-radius: 4px;
      transition: 450ms cubic-bezier(.23,1,.32,1);

      &:focus, &:hover{
        background-color: rgba(153,153,153,.2);

        span{
          color: var(--ion-color-primary);
        }

      }

      span{
        font-size: 14px;
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}
