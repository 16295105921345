div.ngx-mat-timepicker-dialog{

  &>mat-dialog-container.mat-dialog-container{
    background-color: var(--element-background);
    border-radius: 4px !important;
    overflow: hidden;


    .mat-dialog-content {

      mat-toolbar.timepicker-header{
        background-color: var(--ion-color-primary);

        input{
          cursor: pointer;
        }
      }

      .clock-face{
        background-color: var(--ion-color-medium) !important;

        .clock-face__container{

          .clock-face__number{
            &.clock-face__number--outer, &.clock-face__number--inner{
              button.mat-button-disabled{
                color: var(--ion-color-medium-shade);
              }
            }
          }
        }

        .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary{
          background-color: var(--ion-color-primary) !important;
        }
        .clock-face__clock-hand, .clock-face__center{
          background-color: var(--ion-color-primary) !important;
        }
      }
    }

    .mat-dialog-actions{
      button{
        background-color: var(--ion-color-primary);
        color: white;
        margin-left: 5px;
        border-radius: 4px;

        &:focus, &:hover {
          background-color: var(--ion-color-primary-tint);
        }

        span{
          color: white;
        }
      }
    }
  }
}

