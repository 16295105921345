/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
ion-progress-bar {
  height: 18px;
  border-radius: 18px;
  margin: 15px 0 5px;
}

ion-progress-bar.custom-progress::part(progress) {
  background: var(--ion-color-success);
}
ion-progress-bar.custom-progress::part(track) {
  background: var(--grey);
}
ion-progress-bar.custom-progress.red::part(progress) {
  background: var(--ion-color-danger);
}
ion-progress-bar.custom-progress.orange::part(progress) {
  background: var(--ion-color-warning);
}

div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container {
  background-color: var(--element-background);
  border-radius: 4px !important;
  overflow: hidden;
}
div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-content mat-toolbar.timepicker-header {
  background-color: var(--ion-color-primary);
}
div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-content mat-toolbar.timepicker-header input {
  cursor: pointer;
}
div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-content .clock-face {
  background-color: var(--ion-color-medium) !important;
}
div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-content .clock-face .clock-face__container .clock-face__number.clock-face__number--outer button.mat-button-disabled, div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-content .clock-face .clock-face__container .clock-face__number.clock-face__number--inner button.mat-button-disabled {
  color: var(--ion-color-medium-shade);
}
div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-content .clock-face .mat-flat-button.mat-primary, div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-content .clock-face .mat-raised-button.mat-primary, div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-content .clock-face .mat-fab.mat-primary, div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-content .clock-face .mat-mini-fab.mat-primary {
  background-color: var(--ion-color-primary) !important;
}
div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-content .clock-face .clock-face__clock-hand, div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-content .clock-face .clock-face__center {
  background-color: var(--ion-color-primary) !important;
}
div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-actions button {
  background-color: var(--ion-color-primary);
  color: white;
  margin-left: 5px;
  border-radius: 4px;
}
div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-actions button:focus, div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-actions button:hover {
  background-color: var(--ion-color-primary-tint);
}
div.ngx-mat-timepicker-dialog > mat-dialog-container.mat-dialog-container .mat-dialog-actions button span {
  color: white;
}

.cdk-overlay-dark-backdrop {
  background-color: var(--ion-backdrop-color) !important;
}
.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.5;
}

.mat-datepicker-dialog {
  background: transparent;
}
.mat-datepicker-dialog .mat-datepicker-content {
  background: transparent;
}
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container {
  background: var(--element-background);
  border-radius: 4px !important;
  overflow: hidden;
}
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-header {
  background-color: var(--ion-color-primary);
  color: white;
}
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-body-cell-content {
  color: var(--ion-text-color);
}
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-table-header,
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-body-label,
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #607c7c;
}
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-table-header-divider::after {
  background: var(--grey);
}
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-next-button,
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-previous-button {
  color: white;
}
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-arrow,
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-icon-button.mat-button-disabled.mat-button-disabled {
  fill: rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.6);
}
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-body-selected {
  background-color: var(--ion-color-success);
  color: white;
}
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: var(--grey);
}
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-dialog .mat-datepicker-content .mat-datepicker-content-container .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(64, 122, 255, 0.5);
}

.for-settings {
  padding: 10px;
  background: #00000024;
}
.for-settings::part(content) {
  border-radius: 5px;
}

.alert-wrapper {
  background: var(--element-background) !important;
}
.alert-wrapper .alert-head {
  background: var(--ion-color-primary);
  text-align: center;
  margin-bottom: 15px;
}
.alert-wrapper .alert-head h2 {
  color: white;
}
.alert-wrapper .alert-message {
  text-align: center;
  color: var(--ion-text-color);
}
.alert-wrapper .alert-button-group {
  justify-content: center;
}
.alert-wrapper .alert-button-group button {
  background-color: var(--ion-color-primary);
  margin: 0 5px;
  border-radius: 4px;
  transition: 450ms cubic-bezier(0.23, 1, 0.32, 1);
}
.alert-wrapper .alert-button-group button:focus, .alert-wrapper .alert-button-group button:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.alert-wrapper .alert-button-group button:focus span, .alert-wrapper .alert-button-group button:hover span {
  color: var(--ion-color-primary);
}
.alert-wrapper .alert-button-group button span {
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
}

ion-modal .ion-page {
  background: var(--element-background);
}