/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// angular material styles
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

// progress bar
@import "./app/home/events/progress.component.scss";

// timepicker
@import "./app/shared/forms/timepicker.component.scss";

// datepicker
@import "./app/shared/forms/datepicker.component.scss";

// settings modal
@import "./app/shared/settings/settings-modal.component.scss";

// alert
@import "./app/shared/alert.component.scss";

ion-modal{
  .ion-page{
    background: var(--element-background);
  }
}
