.cdk-overlay-dark-backdrop{
  background-color: var(--ion-backdrop-color) !important;

  &.cdk-overlay-backdrop-showing{
    opacity: 0.5;
  }
}

.mat-datepicker-dialog{
  background: transparent;

  .mat-datepicker-content{
    background: transparent;

    .mat-datepicker-content-container{
      background: var(--element-background);
      border-radius: 4px !important;
      overflow: hidden;

      .mat-calendar-header{
        background-color: var(--ion-color-primary);
        color: white;
      }

      .mat-calendar-body-cell-content{
        color: var(--ion-text-color);
      }

      .mat-calendar-table-header,
      .mat-calendar-body-label,
      .mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
        color: #607c7c;
      }

      .mat-calendar-table-header-divider::after{
        background: var(--grey);
      }

      .mat-calendar-next-button,
      .mat-calendar-previous-button{
        color: white;
      }

      .mat-calendar-arrow,
      .mat-icon-button.mat-button-disabled.mat-button-disabled{
        fill: rgba(255,255,255,0.6);
        color: rgba(255,255,255,0.6);
      }

      .mat-calendar-body-selected{
        background-color: var(--ion-color-success);
        color: white;
      }

      .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
        border-color: var(--grey);
      }

      .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
      .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
      .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: rgba(64,122,255,0.5);
      }
    }
  }
}

