ion-progress-bar {
  height: 18px;
  border-radius: 18px;
  margin: 15px 0 5px;

}

ion-progress-bar.custom-progress{

  &::part(progress){
    background: var(--ion-color-success);
  }

  &::part(track){
    background: var(--grey);
  }

  &.red{
    &::part(progress){
      background: var(--ion-color-danger);
    }
  }

  &.orange{
    &::part(progress){
      background: var(--ion-color-warning);
    }
  }
}
